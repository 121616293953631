/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M12 22.8C6.1 22.8 1.2 17.9 1.2 12S6.1 1.2 12 1.2 22.8 6.1 22.8 12 17.9 22.8 12 22.8zm0-20c-5.1 0-9.2 4.1-9.2 9.2s4.1 9.2 9.2 9.2 9.2-4.1 9.2-9.2-4.1-9.2-9.2-9.2z" />
		<path d="M11.9 13.8c-.3 0-.6-.2-.7-.5-.1-.4.1-.8.5-.9 0 0 2.5-.9 2.5-2.3 0-1-.6-1.8-1.5-2.1-.6-.2-1.2-.2-1.7.1-.5.3-1 .7-1.1 1.3-.1.4-.6.6-1 .5-.4-.1-.6-.6-.5-1 .3-.9 1-1.7 1.9-2.1.9-.4 1.9-.5 2.9-.2 1.5.5 2.5 2 2.5 3.5 0 2.5-3.4 3.7-3.5 3.7-.1-.1-.2 0-.3 0zM12 17.8c-.4 0-.8-.3-.8-.8s.3-.8.7-.8c.4 0 .8.3.8.8s-.3.8-.7.8z" />
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
