/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const PlayIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M5 21.8c-.1 0-.2 0-.4-.1s-.4-.4-.4-.7V3c0-.3.1-.5.4-.7.2-.1.5-.1.8 0l14 9c.2.1.3.4.3.6s-.1.5-.3.6l-14 9c-.1.2-.3.3-.4.3zm.8-17.4v15.3L17.6 12 5.8 4.4z" />
	</svg>
))
PlayIconSvg.displayName = 'PlayIconSvg'

const PlayIcon = forwardRef((props, ref) => <Icon component={PlayIconSvg} ref={ref} {...props} />)
PlayIcon.displayName = 'PlayIcon'

PlayIcon.defaultProps = {
	...Icon.defaultProps,
}
PlayIcon.propTypes = {
	...Icon.propTypes,
}

export default PlayIcon
export { PlayIconSvg }
