/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadRightIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M9 18.8c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1L14 12 8.5 6.5c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l6 6c.3.3.3.8 0 1.1l-6 6c-.2.2-.4.3-.6.3z" />
	</svg>
))
ArrowheadRightIconSvg.displayName = 'ArrowheadRightIconSvg'

const ArrowheadRightIcon = forwardRef((props, ref) => <Icon component={ArrowheadRightIconSvg} ref={ref} {...props} />)
ArrowheadRightIcon.displayName = 'ArrowheadRightIcon'

ArrowheadRightIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadRightIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadRightIcon
export { ArrowheadRightIconSvg }
