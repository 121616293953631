/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21.7 5.7c-.1-.1-.1-.2-.1-.2l-3-4c-.1-.2-.4-.3-.6-.3H6c-.2 0-.5.1-.6.3l-3 4s0 .1-.1.1v.1c0 .1-.1.2-.1.3v14c0 1.5 1.2 2.8 2.8 2.8h14c1.5 0 2.8-1.2 2.8-2.8V6c0-.1-.1-.2-.1-.3zM6.4 2.8h11.2l1.9 2.5h-15l1.9-2.5zM19 21.2H5c-.7 0-1.2-.6-1.2-1.2V6.8h16.5V20c-.1.7-.6 1.2-1.3 1.2z" />
		<path d="M16 9.2c-.4 0-.8.3-.8.8 0 1.8-1.5 3.2-3.2 3.2S8.8 11.8 8.8 10c0-.4-.3-.8-.8-.8s-.8.4-.8.8c0 2.6 2.1 4.8 4.8 4.8s4.8-2.1 4.8-4.8c0-.4-.4-.8-.8-.8z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
