/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOffIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19 21.8H5c-1.5 0-2.8-1.2-2.8-2.8V5c0-1.5 1.2-2.8 2.8-2.8h14c1.5 0 2.8 1.2 2.8 2.8v14c0 1.5-1.3 2.8-2.8 2.8zM5 3.8c-.7 0-1.2.5-1.2 1.2v14c0 .7.6 1.2 1.2 1.2h14c.7 0 1.2-.6 1.2-1.2V5c0-.7-.6-1.2-1.2-1.2H5z" />
	</svg>
))
CheckboxOffIconSvg.displayName = 'CheckboxOffIconSvg'

const CheckboxOffIcon = forwardRef((props, ref) => <Icon component={CheckboxOffIconSvg} ref={ref} {...props} />)
CheckboxOffIcon.displayName = 'CheckboxOffIcon'

CheckboxOffIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOffIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOffIcon
export { CheckboxOffIconSvg }
